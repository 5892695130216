import gql from 'graphql-tag'


export const Register = gql`
    mutation register($input: RegisterInput!) {
        register(input: $input) {
            message
        }
    }
`
