import gql from 'graphql-tag'


export const RefreshToken = gql`
    mutation refreshToken($token: String!) {
        refreshToken(refresh_token: $token) {
            token_type
            expires_in
            access_token
            refresh_token
            impersonate
        }
    }
`
