import gql from 'graphql-tag'


export const VerifyEmail = gql`
    mutation verifyEmail($input: VerifyEmailInput!) {
      verifyEmail(input: $input) {
        token_type
        expires_in
        access_token
        refresh_token
        user {
          id
          email
          company{
            is_premium
          }
        }
      }
  }
`
