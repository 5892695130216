import gql from 'graphql-tag'


export const Logout = gql`
    mutation logout {
        logout {
            message
        }
    }
`
