import Cookies from 'universal-cookie'
import moment from 'moment/moment'
import URL from 'url'

import app from '../config/app'


export const getCookiesObj = (req = null) => {
  if (req && req.universalCookies) {
    return req.universalCookies
  }

  return new Cookies()
}

export const setAuthCookie = (value, req = null, rememberMe = null) => {
  const cookies = getCookiesObj(req)

  let rememberMeValue = rememberMe

  if (rememberMe === null) {
    const rememberMeCookie = cookies.get(app.REMEMBER_COOKIE_NAME)

    rememberMeValue = (rememberMeCookie !== undefined && (rememberMeCookie === 'true' || rememberMeCookie === true))
  }

  const expiresDate = rememberMeValue ? moment().add(5, 'year').toDate() : moment().add(1, 'year').toDate()

  cookies.set(app.COOKIE_NAME, value, { path: '/', domain: URL.parse(app.COOKIE_DOMAIN).hostname, secure: app.SECURE_COOKIE, expires: expiresDate })
  if (rememberMeValue) {
    cookies.set(app.REMEMBER_COOKIE_NAME, true, { path: '/', domain: URL.parse(app.COOKIE_DOMAIN).hostname, secure: app.SECURE_COOKIE, expires: expiresDate })
  }
}

export const getAuthCookie = (req = null) => {
  const cookies = getCookiesObj(req)

  return cookies.get(app.COOKIE_NAME)
}

export const removeAuthCookie = (req = null) => {
  const cookies = getCookiesObj(req)

  cookies.remove(app.COOKIE_NAME, { path: '/', domain: URL.parse(app.COOKIE_DOMAIN).hostname, secure: app.SECURE_COOKIE })
  cookies.remove(app.REMEMBER_COOKIE_NAME, { path: '/', domain: URL.parse(app.COOKIE_DOMAIN).hostname, secure: app.SECURE_COOKIE })
}

export const initCookie = (resetCookie = false, req = null) => {
  if (resetCookie) {
    removeAuthCookie(req)
  }
}

export const setAfterAuthRedirectCookie = (value, req = null) => {
  const cookies = getCookiesObj(req)

  const expiresDate = moment().add(1, 'day').toDate()

  cookies.set(app.REDIRECTION_COOKIE_NAME, value, { path: '/', domain: URL.parse(app.COOKIE_DOMAIN).hostname, secure: app.SECURE_COOKIE, expires: expiresDate })
}

export const getAfterAuthRedirectCookie = (req = null) => {
  const cookies = getCookiesObj(req)

  return cookies.get(app.REDIRECTION_COOKIE_NAME)
}

export const removeAfterAuthRedirectCookie = (req = null) => {
  const cookies = getCookiesObj(req)

  cookies.remove(app.REDIRECTION_COOKIE_NAME, { path: '/', domain: URL.parse(app.COOKIE_DOMAIN).hostname, secure: app.SECURE_COOKIE })
}

export const getCookie = (name, req = null) => {
  const cookies = getCookiesObj(req)

  return cookies.get(name)
}
