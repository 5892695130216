import gql from 'graphql-tag'

import { OfferCardFragment } from '../Fragments/OfferCardFragment'


export const Me = gql`
    query Me {
        me {
            id
            first_name
            last_name
            avatar
            email
            phone
            phone_professional
            companyFunction {
                id
                name
            }
            company_function_description
            company {
                id
                name
                logo
                address
                zip_code
                city
                location_name
                siret
                workforce
                description
                phone
                email
                domains {
                    sector {
                        id
                    }
                }
                contactMembers {
                    id
                }
                is_premium
                is_subscribed
                subscription_expires_at
                isActivePremium
                owner {
                    id
                }
                is_ogcn
                activeSepaMandate {
                    id
                    status
                    statusLabel
                    alias
                }
                sepaEnable
            }
            offers {
                ... offerCard
            }
            candidacies(first: 500) {
                data {
                    id
                    message
                    status
                    applied_at
                    offer {
                        ... offerCard
                    }
                }
            }
            notifications {
                id
                type
                message
                payload
                offer_id
            }

            favoritesUsers {
                id
            }

        }
    }
    
    ${OfferCardFragment.fragment}
`
