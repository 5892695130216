import { query } from '../../../helpers/GraphqlHelpers'

import { Register } from './Register'
import { VerifyEmail } from './VerifyEmail'
import { Me } from './Me'
import { Login } from './Login'
import { Logout } from './Logout'
import { Forgot } from './Forgot'
import { Reset } from './Reset'
import { RefreshToken } from './RefreshToken'
import { ActivateManager } from './ActivateManager'


export default {
  register: (variables, headers = {}) => query(Register, variables, headers),
  verifyEmail: (variables, headers = {}) => query(VerifyEmail, variables, headers),
  me: (variables, headers = {}) => query(Me, variables, headers),
  login: (variables, headers = {}) => query(Login, variables, headers),
  logout: (variables, headers = {}) => query(Logout, variables, headers),
  forgot: (variables, headers = {}) => query(Forgot, variables, headers),
  reset: (variables, headers = {}) => query(Reset, variables, headers),
  refreshToken: (variables, headers = {}) => query(RefreshToken, variables, headers),
  activateManager: (variables, headers = {}) => query(ActivateManager, variables, headers),
}
