import gql from 'graphql-tag'


export const ActivateManager = gql`
    mutation activateManagerAccount($input: ManagerAccountInput!) {
        activateManagerAccount(input: $input) {
            token_type
            expires_in
            access_token
            refresh_token
        }
    }
`
