import gql from 'graphql-tag'


export const Reset = gql`
    mutation resetPassword(
        $token: String!,
        $email: String!,
        $password: String!
    ) {
        resetPassword(input: {
            token: $token,
            email: $email,
            password: $password,
            password_confirmation: $password
        }) {
            message
        }
    }
`
