import gql from 'graphql-tag'


export const Forgot = gql`
    mutation forgotPassword($email: String!) {
        forgotPassword(email: $email) {
            message
        }
    }
`
