// Important KEEP IN VANILLA JAVASCRIPT
const { ApolloClient, InMemoryCache, ApolloLink, HttpLink } = require('@apollo/client')
const { onError } = require('@apollo/client/link/error')
const fetch = require('cross-fetch')

const config = require('../config')




const endPoint = config.api.GRAPH_API_URL
const FILTERED_CATEGORIES = ['validation']

const linkError = onError(({ networkError, graphQLErrors, response, operation }) => {
  const bugsnagClient = require('../helpers/BugsnagHelper')

  if (bugsnagClient) {
    if (graphQLErrors) {
      const filteredErrors = graphQLErrors.filter(
        ({ extensions }) => !FILTERED_CATEGORIES.includes(extensions.category)
      )

      filteredErrors.forEach(({ message, locations, path, ...additional }) => {
        const messageToSend = `[GraphQL error]: \
            Message: ${JSON.stringify(message)}, \
            Location: ${JSON.stringify(locations)}, \
            Path: ${JSON.stringify(path)}, \
            Additional: ${JSON.stringify(additional)}`

        if (config.app.APP_ENV === 'local') {
          console.error(messageToSend)
        }

        bugsnagClient.notify(new Error(messageToSend), {
          severity: 'error',
          context: 'graphQLErrors',
          metaData: {
            Message: message,
            Location: locations,
            Path: path,
            Operation: operation,
            Response: response,
            Additional: additional,
          },
        })
      })
    }
    if (networkError) {
      if (config.app.APP_ENV === 'local') {
        console.error(`[Network error]: ${networkError}`)
      }
      bugsnagClient.notify(new Error(`[Network error]: ${networkError}`), {
        severity: 'error',
        context: 'graphQLNetworkError',
        metaData: {
          Operation: operation,
          Response: response,
        },
      })
    }
  }
})

const defaultOptions = {
  watchQuery: {
    fetchPolicy: 'no-cache',
    errorPolicy: 'ignore',
  },
  query: {
    fetchPolicy: 'no-cache',
    errorPolicy: 'all',
  },
}

const httpLink = new HttpLink({ uri: endPoint, fetch })

const apolloClient = new ApolloClient({
  link: ApolloLink.from([linkError, httpLink]),
  cache: new InMemoryCache(),
  name: 'Apollo Client',
  queryDeduplication: false,
  defaultOptions,
})



export default apolloClient
