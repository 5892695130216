import gql from 'graphql-tag'


export const Login = gql`
    mutation login($input: LoginInput!) {
        login(input: $input) {
            token_type
            expires_in
            access_token
            refresh_token
            impersonate
        }
    }
`
