import gql from 'graphql-tag'


export const OfferCardFragment = {
  fragment: gql`
      fragment offerCard on Offer {
          id
          label
          introduction
          maximal_execution_at
          published_at
          type
          company_name
          link
          budget
          slug
          full_slug
          reference
          city
          myCandidacy {
              id
              status
              message
              applied_at
          }
          categories {
              id
              name
              color
              slug
          }
          user {
            id
            company {
                is_ogcn
            }
          }
      }
  `,
}
