import { all, call, put, select } from 'redux-saga/effects'
import moment from 'moment'

import { selectors as AppSelectors } from '../app/redux'
import { actions as AuthActions, selectors as AuthSelectors } from '../auth/redux'
import client from '../../graphql/Client'
import Errors from '../../data/models/Errors'
import auth from '../../graphql/query/Auth'
import { setAuthCookie } from '../../helpers/CookieHelpers'
import * as config from '../../config'

import { actions, selectors } from './redux'


const DEBUG = false
const log = (DEBUG ? console.log : () => null)

export default class ApiSagas {

  static* init() {
  }

  static* initHeaders() {
    const preview = yield select(AppSelectors.preview)


    yield put(actions.setHeaders({
      preview: preview ? 1 : 0,
    }))
  }

  static* getHeaders(checkToken = true) {
    const headers = yield select(selectors.headers)

    let token = yield select(AuthSelectors.token)

    if (checkToken) {
      token = yield call(ApiSagas.getToken)
    }

    return {
      ...headers,
      ...token?.access_token && {
        Authorization: `Bearer ${token.access_token}`,
      },
    }
  }

  static* query(service, variables = null) {
    const headers = yield call(ApiSagas.getHeaders)
    const result = yield call(client.query, service.apply(null, [variables, headers]))

    return result
  }

  static* queryWithoutTokenChecking(service, variables = null) {
    const headers = yield call(ApiSagas.getHeaders, false)
    const result = yield call(client.query, service.apply(null, [variables, headers]))

    return result
  }

  static* checkTokenExpire() {
    const token = yield select(AuthSelectors.token)

    if (token && token.expires && token.refresh_token) {
      const expires = token?.expires && moment(token.expires)
      const diff = -moment().diff(expires, 'minutes')

      log('Api: Token validity', diff)

      if (diff < 60) {
        log('Api: token needs refreshing')
        const result = yield call(ApiSagas.queryWithoutTokenChecking, auth.refreshToken, {
          token: token.refresh_token,
        })

        const newToken = result?.data?.refreshToken

        if (result.errors) {
          log('Api: refresh token error', Errors(result.errors))
          yield put(AuthActions.logout())
        } else {
          log('Api: refresh token success')
          yield put(AuthActions.setToken(newToken))
          setAuthCookie(newToken)
        }

        return newToken
      }
    }
    return token
  }

  static* fetch(url, params) {
    const apiUrl = config.api.API_URL
    const headers = yield call(ApiSagas.getHeaders)

    const response = yield call(fetch, apiUrl + url, {
      ...params,
      headers: {
        ...params?.headers,
        ...headers,
      },
    })

    return response
  }

  static* getToken() {
    yield call(ApiSagas.checkTokenExpire)
    const token = yield select(AuthSelectors.token)

    return token
  }

  static* loop() {
    yield all([
      //
    ])
  }

}
