import gql from 'graphql-tag'
import _ from 'lodash'

import linkType from '../data/enums/linkType'


export const query = (q, variables, headers = {}) => ({
  query: gql`${q}`,
  variables,
  fetchPolicy: 'no-cache',
  errorPolicy: 'all',
  context: {
    headers,
  },
})

export const getSplittedLinks = (data) => {
  const linksByKey = data?.reduce((r, link) => ({ ...r, [link.type]: link }), {})

  return Object.values(linkType).reduce((r, key) => ({ ...r, [key.toLowerCase()]: linksByKey[key]?.value || '' }), {})
}

export const getJoinedLinks = (data, values) => {
  const linksByKey = data?.reduce((r, link) => ({ ...r, [link.type]: link }), {})

  return Object.values(linkType)
    .map((key) => ({ id: linksByKey[key]?.id, type: key, value: values[key.toLowerCase()] || '' }))
    .filter((entry) => !!entry.value)
}

export const getDiffQuery = (sourceProp, valuesProp) => {
  const source = sourceProp ?? []
  const values = valuesProp ?? []

  const sourceById = (source
    && source.reduce((r, entry) => (
      { ...r, [entry.id]: entry }), {})
  ) || {}
  const update = []
  const create = []

  const compare = (value) => {
    const src = sourceById[value.id]

    if (value.id && src) {
      if (!_.isEqual(value, src)) {
        update.push(value)
      }
    } else {
      create.push(value)
    }
  }
  const valuesIds = values.map((entry) => entry.id)
  const _delete = !source ? [] : source.filter((entry) => !valuesIds.includes(entry.id)).map((entry) => entry.id)

  values.forEach(compare)

  return {
    create: create || null,
    update: update || null,
    delete: _delete || null,
  }
}

export const getLinksDiffQuery = (source, values) => {
  const linksValues = getJoinedLinks(source, values)

  return getDiffQuery(source.map(({ id, type, value }) => ({ id, type, value })), linksValues)
}


